import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//
import enLocales from './en.json';
import arLocales from './ar.json';
import ptLocales from './pt.json';

// ----------------------------------------------------------------------

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      // ar: { translations: arLocales },
      // pt: { translations: ptLocales },
    },
    lng: 'en', //localStorage.getItem('i18nextLng') || 'en'
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
