import PropTypes from 'prop-types';
// @mui
import { Box, Typography } from '@mui/material';
// components
import { SkeletonProductItem } from '../../../../components/skeleton';
//
import VenueCard from './VenueCard';

// ----------------------------------------------------------------------

VenueCardList.propTypes = {
  spaces: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default function VenueCardList({ spaces, loading, isLoading }) {
  return (
    <>
      {!isLoading && spaces && !spaces.length ? (
        <Typography variant="body" sx={{ display: 'block' }}>
          No rooms found!
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
            },
          }}
        >
          {(loading ? [...Array(8)] : spaces).map((space, index) =>
            space ? <VenueCard key={space.id} space={space} /> : <SkeletonProductItem key={index} />
          )}
        </Box>
      )}
    </>
  );
}
