import { createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { URL_OVERVIEW } from '../../../utils/restApiUrls';
import { dispatch } from '../../../redux/store';

const KPIS_FETCH_ERROR = 'overview.error.fetchKPIs';
const CHECKINS_FETCH_ERROR = 'overview.error.fetchCheckIns';
const CHECKOUT_SUCCESS = 'overview.success.checkout';
const CHECKOUT_ERROR = 'overview.error.checkout';

const initialState = {
  isKPIsLoading: true,
  isCheckInsLoading: true,
  updatingUuids: [],
  isCheckinsUpdated: false,
  error: null,
  alertError: null,
  alertSuccess: null,
  kpis: null,
  checkIns: null,
  page: 0,
  count: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
  },
});

export default slice.reducer;

export const { updatePage, resetAlertSuccess, resetAlertError } = slice.actions;
