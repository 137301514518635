import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/TokenContext';
import NotistackProvider from './components/NotistackProvider';
import Router from './routes/index';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import ThemeProvider from './theme/index';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
// @ts-ignore
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

function App() {

  const clientId = process.env.REACT_APP_HOST === 'production' 
    ? process.env.REACT_APP_GOOGLE_CLIENT_ID_PROD 
    : process.env.REACT_APP_GOOGLE_CLIENT_ID_PROD;

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <ThemeProvider>
        <ThemeColorPresets>
          <ThemeLocalization>
            <AuthProvider>
              <CollapseDrawerProvider>
              <BrowserRouter>
                <NotistackProvider>
                  <MotionLazyContainer>
                    <Router />
                  </MotionLazyContainer>
                </NotistackProvider>
              </BrowserRouter>
            </CollapseDrawerProvider>
            </AuthProvider>
          </ThemeLocalization>
        </ThemeColorPresets>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
