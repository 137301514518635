// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';
// hooks
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function BlockContent() {
  const { Translate } = useLocales();
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <UploadIllustration sx={{ width: 220 }} />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          <Translate i18nKey='global.selectImageTitle' />
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <Translate i18nKey='venueCreateEdit.dropImage' >
            Drop image here or click
            <Typography
              variant="body2"
              component="span"
              sx={{ color: 'primary.main', textDecoration: 'underline' }}
            >
              browse
            </Typography>
            through your device
          </Translate>
        </Typography>
      </Box>
    </Stack>
  );
}
