import enLocale from "date-fns/locale/en-GB";
import arLocale from "date-fns/locale/ar-SA";
import ptLocale from "date-fns/locale/pt";

// USER ROLES
// ----------------------------------------------------------------------
export const ROLE = {
    USER: "USER",
    SUPER_USER: 'SUPER_USER',
    CUSTOMER_SUPPORT : "CUSTOMER_SUPPORT",
    ORGANISATION_OWNER : "ORGANISATION_OWNER",
    VENUE_MANAGER : "VENUE_MANAGER",
    MEET_MANAGER : "MEET_MANAGER",
    COWORK_MANAGER : "COWORK_MANAGER"
};

export const ROLESSTRINGS={
    'ORGANISATION_OWNER':"Owner",
    'VENUE_MANAGER':"Venue Manager",
    'MEET_MANAGER':"Meet Manager",
    'COWORK_MANAGER':"Cowork Manager",
}
export const AVAILABLE_SLOTS = {
    FULL_DAY_DDR:"FULL_DAY_DDR",
    HALF_DAY_DDR:"HALF_DAY_DDR",
    HOURLY:"HOURLY",
    DAILY:"DAILY"
}

export const BOOKING_TYPES = {
    DDR_FULL_DAY:"Full Day DDR",
    DDR_HALF_DAY:"Half Day DDR",
    HOURLY:"Hourly",
    DAILY:"Daily"
}
export const ROLE_BASED_DISPLAY = {
    ALL  : [1, 2],
    BOOK : [1, 2, 12, 10],
    WORK : [1, 2, 12, 4],
    BOTH : [1, 2, 12],
}

// ENUMS
// ----------------------------------------------------------------------

export const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]

export const BOOKING_TYPE = {
    BLOCK_DATE: "BLOCK_DATE",
    BOOKING: 'BOOKING',
};

export const SPACE_TYPE = {
    MEETING_ROOM :  "Meeting_Room",
    OFFICE : 'Office',
    CREATIVE : 'Creative',
};

export const RESOURCE_TYPES = {
    BOOKING : 'BOOKING',
    PROFILE_PICTURE : 'PROFILE_PICTURE',
    VENUE : "VENUE",
    USER : "USER",
    EVENT : "EVENT",
    SPACE : "SPACE",
    COWORK : "COWORK",
    CHECKIN_REPORT : "CHECKIN_REPORT",
    INVOICE : "INVOICE",
}

export const DURATION_TYPE = {
    PER_HOUR: "perHour",
    PER_DAY: 'perDay',
    HALF_DAY: 'halfDay',
    FULL_DAY: 'fullDay',
};

export const ESTIMATION_TYPE = {
    VENUE: "VENUE",
    USER: 'USER',
};


export const LOCALES = {
    en: enLocale,
    ar: arLocale,
    pt: ptLocale
};

export const paymentTypes = [
    {
        id: "STRIPE_LINK",
        name: "Stripe Link"
    },
    {
        id: "BANK_TRANSFER",
        name: "Bank Transfer"
    },
    {
        id: "CREDIT_CARD",
        name: "Credit Card"
    }
]


export const ORDER_TYPE = {
    INITIATED : "INITIATED",
    PAYMENT_PENDING : "PAYMENT_PENDING",
    CONFIRMED : "CONFIRMED",
    FAILED : "FAILED",
    EXPIRED : "EXPIRED",
    REFUNDED : "REFUNDED",
    CANCELLED : "CANCELLED"
};



export const paymentTypesForVenue = [{id: "PAYMENTATPROPERTY", name: "Payment at property"}]


// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};


export const SPACE_TAB = {
    MEET: 'meet',
    OFFICE: 'office',
    CREATIVE: 'creative',
}

export const SPACE_TAB_VALUE = {
    [SPACE_TAB.MEET]: "Meeting_Room",
    [SPACE_TAB.OFFICE]: "Office",
    [SPACE_TAB.CREATIVE]: "Creative",
}