// @mui
import { Box, Skeleton, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonSchedule() {
  return (
    <>
      <Skeleton variant="text" height={30} />
      <Stack direction="row" spacing={2} alignItems="stretch">
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="text" width={150} height={30} />
        <Stack direction="row" alignItems="stretch">
          <Skeleton variant="text" width={100} height={30} />
          <Skeleton variant="text" width={100} height={30} />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="stretch">
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="text" width={150} height={30} />
        <Stack direction="row" alignItems="stretch">
          <Skeleton variant="text" width={100} height={30} />
          <Skeleton variant="text" width={100} height={30} />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="stretch">
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="text" width={150} height={30} />
        <Stack direction="row" alignItems="stretch">
          <Skeleton variant="text" width={100} height={30} />
          <Skeleton variant="text" width={100} height={30} />
        </Stack>
      </Stack>
    </>
  );
}
