import PropTypes from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// redux
import { useDispatch } from '../../redux/store';
// components
import { FormProvider, RHFTextField } from '../../components/hook-form';
// hooks
import useLocales from '../../hooks/useLocales';
// Custom phone input wrapper
import PhoneInputWrapper from '../../components/PhoneInputWrapper';

// ----------------------------------------------------------------------

const GENERAL_ERROR = 'Could not process this request.';
const CornerCutShapeStyle = styled(Box)(() => ({
  '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
  'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
}));
const phoneRegExp = /^[0-9]{3,4}[ \-]?[0-9]{3,4}[ \-]?[0-9]{3,4}$/;

Yup.addMethod(Yup.string, 'validatePhone', function (errorMessage) {
  return this.test('validate-phone-number', errorMessage, function (value) {
    const { path, createError } = this;
    return phoneRegExp.test(value) || createError({ path, message: errorMessage });
  });
});

// ----------------------------------------------------------------------

FinishSignUp.propTypes = {
  changeScreen: PropTypes.func,
  countries: PropTypes.array,
};

export default function FinishSignUp({ changeScreen, countries }) {
  const { signUp, user } = useAuth();
  const [error, setError] = useState(null); // Initialize error state as null
  const [info, setInfo] = useState(false);
  const { Translate, translate: t } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const FinishSignUpSchema = Yup.object().shape({
    name: Yup.string().required(t('form.error-name')).min(3, t('login.error-fullNameMin')),
    phone_number: Yup.string().required(t('form.error-mobile')),
    email: Yup.string().required(t('form.error-email')).email(t('form.error-validEmail')),
  });

  const defaultValues = {
    name: user?.name || '',
    phone_number: user?.mobile || '',
    email: user?.email || '',
  };

  const methods = useForm({
    resolver: yupResolver(FinishSignUpSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    // Reset error state before submitting the form
    setError(null);
    
    console.log('FinishSignUp onSubmit');
    console.log('form data', data);

    const postData = { ...data }; // 'country_id': data.country, 'signup_uuid': user.signupUuid, password: user.password,
    try {
      const responseData = await signUp(postData);

      if (responseData.error) {
        setError(responseData.error);
      } else if (responseData?.status && responseData.data.token) {
        changeScreen('OTP');
      } else {
        setError({ message: GENERAL_ERROR });
      }
    } catch (error) {
      console.error(error);
      reset();
      setError({ message: error.response.data.message });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!info && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {info}
          </Alert>
        )}
        {!!error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error.message}
          </Alert>
        )}
        <RHFTextField name="name" label={t('login.form-fullName')} InputLabelProps={{ shrink: true }} />
        <RHFTextField name="email" label={t('form.email')} {...(user?.email && { readOnly: true } || {})} InputLabelProps={{ shrink: true }} />
        <Controller
          name="phone_number"
          control={control}
          {...(user?.mobile && { readOnly: true } || {})}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <PhoneInputWrapper label="Mobile" className={error ? 'input-error gradientbg' : 'gradientbg'}>
              <span className="mobile-label">{t('form.mobile')}</span>
              <PhoneInput id="phone_number" defaultCountry="PK" value={value} onChange={onChange} />
              {error && error.message && (
                <Typography variant="caption" color="error.main" sx={{ mt: 1, ml: 2 }} paragraph>
                  {error.message}
                </Typography>
              )}
            </PhoneInputWrapper>
          )}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 4 }}>
        <Typography sx={{ color: '#767676', display: 'flex' }}>
          Already have an account?
          <Link component={RouterLink} variant="body1" to={PATH_AUTH.login} sx={{ fontWeight: 'fontWeightMedium', pl: 1 }}>
            Login
          </Link>
        </Typography>
        <CornerCutShapeStyle sx={{ mb: 2, position: 'relative' }}>
          <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
            Sign up
          </LoadingButton>
        </CornerCutShapeStyle>
      </Stack>
    </FormProvider>
  );
}
