import {format, parseISO} from "date-fns";
import {toYYMMDD} from "../pages/dashboard/customers/SpaceCreateEditSlice";
import {ROLE_BASED_DISPLAY} from "../config";
import {ROLE, SPACE_TYPE} from "./constants";

export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


export const convertNumbThousand = (x = 0) => {
    try {
        if (!x) {
            return "0";
        }
        return x.toLocaleString("en-US");
    } catch (e) {
        const xString = x?.toString() || '-';
        return xString;
    }
};


// Helper function to add ordinal suffix
export const addOrdinalSuffix = (day) => {
    const s = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return day + (s[(v - 20) % 10] || s[v] || s[0]);
};


// Custom format function
export const formatDate = (dateString) => {
    const date = parseISO(dateString);
    const day = format(date, 'EEE'); // Day of the week, abbreviated
    const month = format(date, 'MMM'); // Month, abbreviated
    const dayOfMonth = format(date, 'd'); // Day of the month

    // Use parseInt with a radix parameter
    const dayWithSuffix = addOrdinalSuffix(parseInt(dayOfMonth, 10));

    return `${day} ${month} ${dayWithSuffix}`;
}



export function getWeekday(dayString) {
    const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const dayIndex = daysOfWeek.indexOf(dayString.toUpperCase());
    return dayIndex >= 0 ? dayIndex : "Invalid day string";
}

export function getEndDateTime(selectedDate, currentDurationType) {
    let startDate = new Date(selectedDate);
    let endDate = new Date(startDate);

    switch (currentDurationType) {
        case 'perHour':
            endDate.setHours(endDate.getHours() + 1);
            break;
        case 'perDay':
            endDate.setHours(endDate.getHours() + 24);
            break;
        case 'halfDay':
            endDate.setHours(endDate.getHours() + 12);
            break;
        case 'fullMonth':
            endDate.setMonth(endDate.getMonth() + 1);
            break;
        default:
            throw new Error('Unknown duration type');
    }

    const formattedEndDate = endDate.toISOString().split('T')[0]; // Format to yyyy-mm-dd
    const formattedEndTime = endDate.toTimeString().split(' ')[0].slice(0, 5); // Format to hh:mm

    return {
        end_date: formattedEndDate,
        end_time: formattedEndTime,
    };
}

export const todaysDate = ()=>{
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate
}

export function formatDateToYYYYMonthDD(dateString) {
    const date = new Date(dateString); // Parse the input date string (YYYY-MM-DD)

    // Format the date to 'DD Month YYYY'
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}


export const appendQuery = (key, value) => {
    const url = new URL(window.location);
    url.searchParams.set(key, value);
    window.history.pushState({}, '', url); // Updates the URL without reloading the page
};

export const spaceTabBasedOnUserRole = (role,general=false) => {
    switch (role) {
        case ROLE.MEET_MANAGER:
            return 'meet';

        case ROLE.COWORK_MANAGER:
            return 'cowork';

        default:
            return  general ? 'general' : 'cowork';
    }
};

export const spaceModeBasedOnUserRole = (role) => {
    switch (role) {
        case ROLE.MEET_MANAGER || ROLE.COWORK_MANAGER:
            return 'space';

        default:
            return  'venue';
    }
};

export const spaceTabType = (type) => {
    switch (type) {
        case SPACE_TYPE.MEETING_ROOM:
            return 'meet';
        case SPACE_TYPE.OFFICE:
            return 'office';
        default:
            return  'creative';
    }
};

export function capitalization (str){
    if (!str) return str; // Return the string as is if it's empty or undefined
    return str
        .split(' ')               // Split the string into an array of words
        .map(word =>               // Map through each word
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
};