import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Typography, Card, CardContent } from '@mui/material';
import { SeverErrorIllustration } from '../assets';
// hooks
import useLocales from '../hooks/useLocales';
//
import Iconify from './Iconify';

// ----------------------------------------------------------------------
const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.background.info,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

// ----------------------------------------------------------------------

PageError.propTypes = {
  error: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
};

export default function PageError({ error='', header='global.error', subheader=''}) {
  const theme = useTheme();
  const { Translate } = useLocales();
  const ERROR_DARK = theme.palette.text.info;
  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          color: 'grey.800',
        }}
      >
        <Typography gutterBottom variant="h6" color={ERROR_DARK} sx={{ alignItems: 'center', display: 'flex', pr: 5}}>
          <Iconify icon={'bx:error'} sx={{ mr: 1 }} />
          <Translate i18nKey={header} />
        </Typography>
        {
          subheader && (
            <Typography gutterBottom variant="body1" color={ERROR_DARK}>
              <Translate i18nKey={subheader} />
            </Typography>
          )
        }
        <Typography gutterBottom variant="subtitle2" color={ERROR_DARK}>
          <Translate i18nKey={error} />
        </Typography>

      </CardContent>

      <SeverErrorIllustration
        sx={{
          p: 3,
          width: 360,
          maxWidth: '200px',
          margin: { xs: 'auto', md: 'inherit' },
        }}
      />
    </RootStyle>
  );
}
