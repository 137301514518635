import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function OnboardingLayout() {
  const { isAuthenticated, isInitialized, user } = useAuth();

  const userType = window.localStorage.getItem('lwt_ut') && +window.localStorage.getItem('lwt_ut') || 0;

  if(isAuthenticated && user?.user_type !== userType){
    console.log('DashboardLayout userType', user?.user_type, userType);
    // window.location.reload();
  }

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');
  const { branchid = '' } = useParams();

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  if(!branchid || 1){
    return <Outlet />;
  }
  
  return (
    <>
      <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

      <Box
        component="main"
        sx={{
          // px: { lg: 2 },
          // pt: {
          //   xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
          //   lg: `${HEADER.MAIN_DESKTOP_HEIGHT + 0}px`,
          // },
          // pb: {
          //   xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
          //   lg: `${HEADER.MAIN_DESKTOP_HEIGHT + 0}px`,
          // },
        }}
      >
        <Outlet />
      </Box>
    </>
  ); 
}
