import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
// import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import useLocales from '../../hooks/useLocales';

RHFMultiSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  listItems: PropTypes.array,
  label: PropTypes.string,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RHFMultiSelect({ name, listItems, label, children, disabled, ...other }) {
  const { control } = useFormContext();
  const { Translate } = useLocales();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl>
          <InputLabel id={`label-${name}`}>{label}</InputLabel>
          <Select
            labelId={`label-${name}`}
            id="demo-multiple-checkbox"
            multiple
            value={value}
            onChange={onChange}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => {
              const count = selected.length;
              return (
                <Translate i18nKey="venueCreateEdit.selected" count={count}>
                  {{ count }} selected
                </Translate>
              );
            }}
            // renderValue={(selected) => `${selected.length} Selected`}
            MenuProps={MenuProps}
            error={!!error}
            {...(disabled && { disabled: true} || {})}
          >
            {listItems &&
              listItems.map((person) => (
                <MenuItem key={person.id} value={person.id}>
                  <Checkbox checked={value.indexOf(person.id) > -1} />
                  <ListItemText primary={person.name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
