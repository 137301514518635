import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, Typography, Box, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
import useResponsive from '../../hooks/useResponsive';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { PATH_AUTH } from '../../routes/paths';
import { GoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import Iconify from 'src/components/Iconify';


// assets

// ----------------------------------------------------------------------
const GENERAL_ERROR = 'Could not process this request.';
const CornerCutShapeStyle = styled(Box)(() => ({
  '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
  'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
}));
// ----------------------------------------------------------------------

LoginForm.propTypes = {
  changeScreen: PropTypes.func,
};

// ----------------------------------------------------------------------

Yup.addMethod(Yup.string, "validatePhone", function (errorMessage) {
  // console.log('this',this);
  return this.test(`validate-phone-number`, errorMessage, (value) => {
    const { path, createError } = this;
    return (
      isValidPhoneNumber(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

// ----------------------------------------------------------------------

export default function LoginForm({ changeScreen }) {
  const { login, socialLogin } = useAuth();
  const { translate, currentLang } = useLocales();
  const isDesktop = useResponsive('up', 'sm');

  const isMountedRef = useIsMountedRef();

  const [continueWithEmail, setContinueWithEmail] = useState(true);

  const LoginSchema = Yup.object().shape({
    email: continueWithEmail && Yup.string().email(translate('form.error-validEmail')).required(translate('form.error-email')),
    // mobile: (!continueWithEmail) &&
    //   Yup.string()
    //     .required(translate('form.error-mobile'))
    //     .validatePhone(translate('form.error-validMobile')),
    // password: Yup.string()
    //   .required(translate('form.error-password'))
    //   .min(8, translate('form.error-validPassword')),
  });

  const defaultValues = {
    email: '',
    // mobile: '',
    // password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    // const postData = { ...(continueWithEmail && { email: data.email} || {mobile: data.mobile}), password: data.password};
    try {
      const responseData = await login(data.email);

      if (responseData.error?.message || 0) {
        setError('afterSubmit', responseData.error);
      } else if (responseData?.status && responseData.data.token) {
        changeScreen('OTP');
      } else {
        console.log("error");
        setError('afterSubmit', { message: GENERAL_ERROR });
      }

    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error response:', error.response);
        console.log(`nError Response:`, JSON.stringify(error.response.data.message));
      }
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { message: error.response.data.message });
      }
    }
  };


  const handleGoogleLoginSuccess = async (response) => {

    try {
      const responseData = await socialLogin(response.credential, "GOOGLE");
    } catch (error) {
      // if (error.response) {
      //   // The request was made and the server responded with a status code
      //   // that falls out of the range of 2xx
      //   console.error('Error response:', error.response);
      //   console.log(`nError Response:`, JSON.stringify(error.response.data.message));
      // }
      // console.error(error);
      //   setError({ message: error.response.data.message });
    }

  };

  const handleAppleLoginSuccess = async (response) => {
    try {
      const responseData = await socialLogin(response?.authorization?.id_token, "APPLE");
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error response:', error.response);
        console.log(`nError Response:`, JSON.stringify(error.response.data.message));
      }
      console.error(error);
      setError({ message: error.response.data.message });
    }
  };

  const handleAppleLoginError = (error) => {
    console.error('Apple login error:', error);
  };


  return (
    <div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
          <RHFTextField
            name="email"
            InputLabelProps={{ shrink: true }}
            label={translate('form.email')}
            placeholder={translate('form.placeholder-email')}
            sx={{ mb: 2 }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 4 }}>
          <Typography sx={{ color: '#767676', display: 'flex' }}>
            {/* Don't have an account?
            <Link component={RouterLink} variant="body1" to={PATH_AUTH.signup} sx={{ fontWeight: 'fontWeightMedium', pl: 1 }}>
              Sign up
            </Link> */}
          </Typography>
          <CornerCutShapeStyle sx={{ mb: 2, position: 'relative' }}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {/* {translate('login.btn-continue')} */}
              Login
            </LoadingButton>
          </CornerCutShapeStyle>
        </Stack>
        <Divider/>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 4 }}>
          <GoogleLogin onSuccess={handleGoogleLoginSuccess} onError={(error) => console.error(error)} />
          <Typography variant="p" align="left">
          or
        </Typography>
          <AppleSignin
            authOptions={{
              clientId: 'io.letswork.web.staging.services',
              redirectURI: window.location.origin +"/", // redirect URI
              scope: 'name email',
              usePopup: true,
            }}
            onSuccess={handleAppleLoginSuccess}
            onError={handleAppleLoginError}
            render={(props) => (
              <LoadingButton style={{color:"#3c4043",fontWeight:'normal',paddingTop:8,paddingBottom:8,paddingLeft:18,paddingRight:18,marginTop:3,backgroundColor:"#ffffff",borderColor:'#dbdce0'}} onClick={props.onClick} size="medium" variant="outlined" startIcon={<Iconify icon={'ic:baseline-apple'} />}>
                Sign in with Apple
              </LoadingButton>

            )}
          /></Stack>
      </FormProvider>
    </div>
  );
}
