import { createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { URL_OVERVIEW, URL_CUSTOMERS } from '../../../utils/restApiUrls';
import getRange from '../../../utils/getRange';
import { dispatch } from '../../../redux/store';

const ACTIVE_CHECKINS_FETCH_ERROR = 'customers.error.fetchActiveCheckIns';
const CHECKOUT_SUCCESS = 'customers.success.checkout';
const CHECKOUT_ERROR = 'customers.error.checkout';
const ALL_CHECKINS_FETCH_ERROR = 'customers.error.fetchAllCheckIns';
const REVIEWS_FETCH_ERROR = 'customers.error.fetchReviews';

const initialState = {
  isLoading: true,
  isActiveCheckInsUpdating: false,
  isAllCheckInsUpdating: false,
  isReviewsUpdating: false,
  updatingUuids: [],
  error: null,
  alertSuccess: null,
  alertError: null,
  duration: 'month',
  dateRange: null,
  modalOpen: false,
  modalLoading: false,
  start: null,
  end: null,
  page: 0,
  count: 0,
  order: 'asc',
  searchQuery: '',
  rowsPerPage: 10,
  totalPages: 0,
  customers: {
    activeCheckIns: null,
    allCheckIns: null,
    reviews: null,
  },
};

const slice = createSlice({
  name: 'coworkCustomers',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    setModalLoading(state, action) {
      state.modalLoading =  action.payload;
    },

    setModalOpen(state, action) {
      state.modalOpen =  action.payload;
    },

    startActiveCheckInsUpdating(state) {
      state.isActiveCheckInsUpdating = true;
    },

    startAllCheckInsUpdating(state) {
      state.isAllCheckInsUpdating = true;
    },

    startReviewsUpdating(state) {
      state.isReviewsUpdating = true;
    },

    startCheckoutUpdating(state, action) {
      const uuid = action.payload;
      state.updatingUuids.push(uuid);
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setAlertSuccess(state, action) {
      state.alertSuccess = action.payload;
    },

    setAlertError(state, action) {
      state.alertError = action.payload;
    },

    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    resetAlertError(state) {
      state.alertError = null;
    },

    loadingDone(state) {
      state.isLoading = false;
      state.isActiveCheckInsUpdating = false;
      state.isAllCheckInsUpdating = false;
      state.isReviewsUpdating = false;
    },

    resetPagination(state, action) {
      const tab = action.payload;
      console.log('resetPagination redux tab', tab);
      state.page = 0;
      state.count = 0;
      state.rowsPerPage = 10;
      // if (tab === 'Reviews') {
      //   state.page = 1;
      // } else {
      //   state.page = 0;
      // }
    },

    getActiveCheckInsSuccess(state, action) {
      state.isLoading = false;
      state.isActiveCheckInsUpdating = false;
      // eslint-disable-next-line camelcase
      const { checkins, pagination } = action.payload;
      const { total_items, current_page,per_page } = pagination;
      if (checkins.length) {
        state.customers.activeCheckIns = checkins;
        // eslint-disable-next-line camelcase
        state.page = current_page - 1;
        // eslint-disable-next-line camelcase
        state.count = total_items;
        // eslint-disable-next-line camelcase
        state.rowsPerPage = per_page;
      } else {
        state.count = 0;
        state.customers.activeCheckIns = [];
      }
    },

    getAllCheckInsSuccess(state, action) {
      state.isLoading = false;
      state.isAllCheckInsUpdating = false;
      // eslint-disable-next-line camelcase
      const { checkins, pagination } = action.payload;
      const { total_items, current_page,per_page } = pagination;
      if (checkins.length) {
        state.customers.allCheckIns = checkins;
        // eslint-disable-next-line camelcase
        state.page = current_page - 1;
        // eslint-disable-next-line camelcase
        state.count = total_items;
        // eslint-disable-next-line camelcase
        state.rowsPerPage = per_page;
      } else {
        state.count = 0;
        state.customers.allCheckIns = [];
      }
    },

    getReviewsSuccess(state, action) {
      state.isLoading = false;
      state.isReviewsUpdating = false;
      const {
        results,
        current_page: page,
        total_count: count,
        per_page_record: rowsPerPage,
        total_pages: totalPages,
      } = action.payload;

      if (results.length) {
        state.customers.reviews = results;
        state.page = page - 1;
        state.count = count;
        state.rowsPerPage = rowsPerPage;
        state.totalPages = totalPages;
      } else {
        state.count = 0;
        state.customers.reviews = [];
      }
    },

    getCheckoutSuccess(state, action) {
      const uuid = action.payload;
      console.log('uuid', uuid);

      const newUpdatingUuids = state.updatingUuids.filter((item) => item !== uuid);
      state.updatingUuids = newUpdatingUuids;

      state.customers.activeCheckIns = state.customers.activeCheckIns.filter((checkIn) => {
        console.log('checkIn', checkIn);
        return checkIn.uuid !== uuid;
      });
      // const {
      //   results,
      //   current_page: page,
      //   total_count: count,
      //   per_page_record: rowsPerPage,
      //   total_pages: totalPages,
      // } = action.payload;

      // if (results.length) {
      //   state.customers.reviews = results;
      //   state.page = page - 1;
      //   state.count = count;
      //   state.rowsPerPage = rowsPerPage;
      //   state.totalPages = totalPages;
      // } else {
      //   state.count = 0;
      //   state.customers.reviews = [];
      // }
    },

    updateDuration(state, action) {
      const { newDuration, tab } = action.payload;
      state.duration = newDuration;
      console.log('tab', tab);
      state.page = 0;
      // if (tab === 'Reviews') {
      //   state.page = 1;
      // } else {
      //   state.page = 0;
      // }
      if (newDuration !== 'custom') {
        state.dateRange = null;
      }
    },

    updatePage(state, action) {
      console.log('redux updatePage', action.payload);
      state.page = action.payload;
      // state.updateTimeStamp = fTimestampNow();
    },

    handleAllCheckInsSearch(state, action) {
      state.searchQuery = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setModalLoading,
  setModalOpen,
  updateDuration,
  updatePage,
  resetPagination,
  handleAllCheckInsSearch,
  resetAlertSuccess,
  resetAlertError,
} = slice.actions;

export function getActiveCheckIns(durationLabel, page,id) {
  return async () => {
    dispatch(slice.actions.startActiveCheckInsUpdating());
    try {
      const response = await axios.post(URL_CUSTOMERS.checkIns + `?active_checkins=${true}&page=${page + 1}`, {
        // start_date: "2024-08-02",
        // end_date: "2024-08-28",
        ...getRange('day'),
        cowork_ids: id ? [id] : []
      });
      if (response.status) {

        const { data } = response;
        dispatch(slice.actions.getActiveCheckInsSuccess(data?.data));
      } else {
        dispatch(slice.actions.loadingDone());
        dispatch(slice.actions.hasError(response.data.error?.title || ACTIVE_CHECKINS_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.title || ACTIVE_CHECKINS_FETCH_ERROR));
      }
    } catch (error) {
      console.log('getActiveCheckIns', error);
      dispatch(slice.actions.loadingDone());
      dispatch(slice.actions.hasError(ACTIVE_CHECKINS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(ACTIVE_CHECKINS_FETCH_ERROR));
    }
  };
}

export function getAllCheckIns(durationLabel, page, search,id) {
  return async () => {
    dispatch(slice.actions.startAllCheckInsUpdating());
    try {
      const response = await axios.post(
          `${URL_CUSTOMERS.checkIns}?page=${page + 1}${search ? `&search_text=${search}` : ''}`,
          {
            ...getRange(durationLabel),
            cowork_ids: id ? [id] : []
          }
      );

      // const response = await axios.post(URL_CUSTOMERS.checkIns, {
      //   params: {
      //     // page: page + 1,
      //     ...((search && { search }) || {}),
      //     ...getRange(durationLabel),
      //   },
      // });
      if (response.status) {
        const { data } = response;
        dispatch(slice.actions.getAllCheckInsSuccess(data?.data));
      } else {
        dispatch(slice.actions.loadingDone());
        dispatch(slice.actions.hasError(response.data.error?.title || ALL_CHECKINS_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.title || ALL_CHECKINS_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.loadingDone());
      dispatch(slice.actions.hasError(ALL_CHECKINS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(ALL_CHECKINS_FETCH_ERROR));
    }
  };
}

export function getReviews({ durationLabel, page }) {
  return async () => {
    dispatch(slice.actions.startReviewsUpdating());
    try {
      const response = await axios.get(URL_CUSTOMERS.reviews, {
        params: {
          p: page + 1,
          ...getRange(durationLabel),
        },
      });
      if (response.data.status) {
        const { data } = response;
        dispatch(slice.actions.getReviewsSuccess(data));
      } else {
        dispatch(slice.actions.loadingDone());
        dispatch(slice.actions.hasError(response.data.error?.title || REVIEWS_FETCH_ERROR));
        dispatch(slice.actions.setAlertError(response.data.error?.title || REVIEWS_FETCH_ERROR));
      }
    } catch (error) {
      dispatch(slice.actions.loadingDone());
      dispatch(slice.actions.hasError(REVIEWS_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(REVIEWS_FETCH_ERROR));
    }
  };
}

export function checkout(uuid) {
  return async () => {
    dispatch(slice.actions.startCheckoutUpdating(uuid));
    try {
      const response = await axios.post(URL_OVERVIEW.checkout, {
        uuid,
      });
      if (response.data.status) {
        // const { data } = response;
        dispatch(slice.actions.getCheckoutSuccess(uuid));
        dispatch(slice.actions.setAlertSuccess(CHECKOUT_SUCCESS));
      } else {
        dispatch(slice.actions.loadingDone());
        dispatch(
          slice.actions.setAlertError(response.data.error?.title || response.data.error?.message || CHECKOUT_ERROR)
        );
      }
    } catch (error) {
      console.log('coworkcustomer checkout', error);
      dispatch(slice.actions.loadingDone());
      dispatch(slice.actions.setAlertError(CHECKOUT_ERROR));
    }
  };
}

export function markInvalidCheckout(uuid) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.setModalLoading(true));
    try {
      const response = await axios.put(URL_CUSTOMERS.invalidCheckIns, {
        checkin_id: uuid,
      });
      if (response.data.status) {
        // const { data } = response;
        dispatch(slice.actions.getCheckoutSuccess(uuid));
        dispatch(slice.actions.setAlertSuccess(CHECKOUT_SUCCESS));
        const state = getState();
        const { duration, page } = state.coworkCustomers;
        try{
          const response = await axios.post(URL_CUSTOMERS.checkIns + `?active_checkins=${true}&page=${page + 1}`, {
            "start_date": "2024-08-02",
            "end_date": "2024-08-28",
            "cowork_ids": []
          });
          if (response.status) {
            const { data } = response;
            dispatch(slice.actions.getActiveCheckInsSuccess(data?.data));
          }
        }catch (error) {
          console.log('getActiveCheckIns', error);
          dispatch(slice.actions.loadingDone());
          dispatch(slice.actions.hasError(ACTIVE_CHECKINS_FETCH_ERROR));
          dispatch(slice.actions.setAlertError(ACTIVE_CHECKINS_FETCH_ERROR));
        }
      } else {
        dispatch(slice.actions.loadingDone());
        dispatch(
            slice.actions.setAlertError(response.data.error?.title || response.data.error?.message || CHECKOUT_ERROR)
        );
      }
    } catch (error) {
      console.log('coworkcustomer checkout', error);
      dispatch(slice.actions.loadingDone());
      dispatch(slice.actions.setAlertError(CHECKOUT_ERROR));
    }finally {
      dispatch(slice.actions.setModalLoading(false));
      dispatch(slice.actions.setModalOpen(false));
    }
  };
}
