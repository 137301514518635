import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from 'axios';
import { isValidToken, setSession } from '../utils/jwt';
import { URL_AUTH } from '../utils/restApiUrls';
import { TRACK_SIGNUP, TRACK_LOGIN, TRACK_LOGOUT } from '../utils/trackAnalytics';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: true,
  user: null,
  wifi: 0,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      // isInitialized: true,
      user,
    };
  },
  UUID: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    }
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    console.log(user,'instate')
    const userType = user?.user_type || 0;
    window.localStorage.setItem('lwt_ut', userType);
    return {
      ...state,
      isAuthenticated: true,
      user: { ...state.user, ...user, user_type: 1 },
    };
  },
  NEW_SOCIAL_LOGIN: (state, action) => {
    const { user } = action.payload;
    window.localStorage.setItem('lwt_ut', 0);
    return {
      ...state,
      user,
    }
  },
  SOCIAL_LOGIN: (state, action) => {
    const { user } = action.payload;
    window.localStorage.setItem('lwt_ut', 0);
    return {
      ...state,
      isAuthenticated: true,
      user: { ...state.user, ...user },
    };
  },
  LOGOUT: (state) => {
    window.localStorage.setItem('lwt_ut', 0);
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  WIFISPEED: (state, action) => {
    const { wifi } = action.payload;

    return {
      ...state,
      wifi,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  finishNormalSignUp: () => Promise.resolve(),
  verifyOTPLogin: () => Promise.resolve(),
  socialLogin: () => Promise.resolve(),
  verifyOTP: () => Promise.resolve(),
  resendOTP: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  setPassword: () => Promise.resolve(),
  finishSignUp: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  wifiUpdate: () => Promise.resolve(),
  userUpdate: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      if (process.env.REACT_APP_USE_MOCK === 2) {
        setSession('1234');
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              name: 'Mock User',
              email: 'mock.user@letswork.io',
              user_id: '1234',
              x_token: 'mockusertesting'
            },
          },
        });
      } else {
        try {
          const accessToken = window.localStorage.getItem('accessToken');

          if (accessToken && isValidToken(accessToken)) {
            setSession(accessToken);

            let user = window.localStorage.getItem('lwt_user');
            user = JSON.parse(user)
            // if(status){
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: { ...user, user_type: 1 }
              },
            });
            // } else {
            //   setSession(null);
            //   dispatch({
            //     type: 'INITIALIZE',
            //     payload: {
            //       isAuthenticated: false,
            //       user: null,
            //     },
            //   });
            // }

          } else {
            // console.log(accessToken,"accessTokenaccessToken");
            // dispatch({
            //   type: 'INITIALIZE',
            //   payload: {
            //     isAuthenticated: false,
            //     user: null,
            //   },
            // });
          }
        } catch (err) {
          console.error(err);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });

        }
      }
    };

    initialize();
  }, []);

  const login = async (email) => {
    const memberNotAllowed = { member_not_allowed: 1 };
    const body = { email };
    const response = await axios.post(URL_AUTH.login, body);
    const { status, data } = response.data;

    if (status) {
      if (response.data.data.token) {
        const payload = {
          email,
          token: response.data.data.token,
        }
        dispatch({
          type: 'UUID',
          payload: {
            user: payload
          }
        })
      }
    } else {
      setSession(null);
    }

    return response.data;
  };


  const finishNormalSignUp = async (data) => {
    const response = await axios.post(URL_AUTH.finishVenueSignUp, {
      ...data,
    });
    setSession(null);

    console.log(
      `${URL_AUTH.finishVenueSignUp}\nResponse:`,
      JSON.stringify(response.data)
    );

    const { status, data: context, message, is_new_user: isNewFlag } = response.data;

    if (status) {
      TRACK_SIGNUP({ userId: context.user_id, user: context })
      setSession(context.x_token);
      const isNew = isNewFlag || context?.user_landing_url === "/welcome/"
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            ...context,
            isNew,
          },
        },
      });

    } else {
      setSession(null);
    }
    return { status, context, error: { message }, isNewFlag };
  };

  const verifyOTPLogin = async (payload) => {
    const response = await axios.post(URL_AUTH.verifyOTPLogin, payload);

    const { status, data, is_new_user: isNewFlag } = response.data;

    if (status) {
      // TRACK_LOGIN({ userId: data.user.id, user: data.user });

      localStorage.setItem('org_id', data?.user?.organisation_user_details?.organisation?.id);
      setSession(data.token.access_token);
      // const isNew = isNewFlag || context?.user_landing_url === "/letsmeet/welcome/"

      window.localStorage.setItem('lwt_user', JSON.stringify(data.user));
      const isNew = !data.user.organisation_user_details?.organisation?.is_venue_exist && data.user.organisation_user_details;
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            ...data.user,
            isNew: isNew,
          },
        },
      });
    } else {
      setSession(null);
    }
    return response.data;
  };

  const signUp = async (data) => {
    const response = await axios.post(URL_AUTH.signup, {
      ...data
    })
    setSession(null);
    const { status, context, error } = response.data;

    if (status) {
      if (response.data.data.token) {
        const payload = {
          ...data,
          token: response.data.data.token,
        }
        dispatch({
          type: 'UUID',
          payload: {
            user: payload
          }
        })
      }

    } else {
      setSession(null);
      return { error: error.message || 'Signup failed' };
    }
    return response.data;
  }

  const verifyOTP = async (data) => {
    const response = await axios.post(URL_AUTH.verifyOTP, data)

    const { status } = response.data;

    if (status) {
      console.log('OTP verified');
    } else {
      setSession(null);
    }
    return response.data;
  }

  const resendOTP = async (data) => {
    const response = await axios.post(URL_AUTH.resendOTP, data)

    return response.data;
  }

  const resetPassword = async (data) => {
    const response = await axios.post(URL_AUTH.resetPassword, { email_id: data.email, source: 'web' });

    return response.data;
  }

  const setPassword = async (data) => {
    const response = await axios.post(URL_AUTH.setPassword, { ...data, source: 'web' });

    return response.data;
  }

  const finishSignUp = async (data) => {
    const { email, mobile, name, password, signupUuid, country } = data;
    const response = await axios.post(URL_AUTH.finishSignUp, {
      'user_type': '10',
      ...data,
      // 'name': name,
      // 'email': email,
      // 'mobile': mobile,
      // 'password': password,
      // 'signup_uuid': signupUuid,
      // 'country_id': country,
      'location_id': 2,
    })
    setSession(null);


    const { status, error } = response.data;

    if (!status) {
      setSession(null);
      return { error: error.message || error.title || 'Signup failed' };
    }
    TRACK_SIGNUP(data);
    return { error: null };
  }

  const socialLogin = async (token, source) => {
    let isNewUser;
    let errorResponse = {};
    const response = await axios.post(URL_AUTH.socialLogin, { token: token, source: source });

    // eslint-disable-next-line camelcase

    const { status, data, is_new_user: isNewFlag } = response.data;

    if (status) {
      // TRACK_LOGIN({ userId: data.user.id, user: data.user });

      localStorage.setItem('org_id', data?.user?.organisation_user_details?.organisation?.id);
      setSession(data.token.access_token);
      // const isNew = isNewFlag || context?.user_landing_url === "/letsmeet/welcome/"

      window.localStorage.setItem('lwt_user', JSON.stringify(data.user));
      const isNew = !data.user.organisation_user_details?.organisation?.is_venue_exist && data.user.organisation_user_details;
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            ...data.user,
            isNew: isNew,
          },
        },
      });
    } else {
      setSession(null);
    }
    return response.data;
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    // TRACK_LOGOUT();
    // axios.get(URL_AUTH.logout);
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const wifiUpdate = async (speed) => {
    // console.log("wifiUpdate", speed, (new Date).getTime())
    dispatch({
      type: 'WIFISPEED',
      payload: {
        wifi: speed
      },

    });
  };

  const userUpdate = async (data) => {
    // console.log("wifiUpdate", speed, (new Date).getTime())
    console.log('in function')
    dispatch({
      type: 'LOGIN',
      payload: {
        user: data
      },

    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        verifyOTPLogin,
        signUp,
        finishNormalSignUp,
        socialLogin,
        verifyOTP,
        resendOTP,
        resetPassword,
        setPassword,
        finishSignUp,
        logout,
        register,
        wifiUpdate,
        userUpdate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
