// routes
import { PATH_DASHBOARD } from '../routes/paths';
import enLocale from "date-fns/locale/en-GB";
import arLocale from "date-fns/locale/ar-SA";
import ptLocale from "date-fns/locale/pt";

// API
// ----------------------------------------------------------------------



// export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';
export const HOST_API = (process.env.REACT_APP_HOST === 'staging') && process.env.REACT_APP_HOST_API_KEY_STG ||
(process.env.REACT_APP_HOST === 'dev') && process.env.REACT_APP_HOST_API_KEY_DEV ||
process.env.REACT_APP_HOST_API_KEY_PROD || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;


// ROOT PATH AFTER LOGIN SUCCESSFUL
// export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.dashboard.overview; // as '/dashboard/app'



// USER ROLE GROUPs
// ----------------------------------------------------------------------




/*
 *
  USER_SUPER_ADMIN = 1
  USER_ADMIN = 2
  USER_MEMBER = 3
  USER_BRANCH_MANAGER = 12
  USER_LM_VENUE_MANAGER = 10
  USER_COUNTRY_MANAGER = 11
  USER_COWORK_MANAGER = 4
 */

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  // themeColorPresets: 'blue',
  themeLayout: 'horizontal',
  themeStretch: false,
};
