import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { URL_ORGANIZATION, URL_PLACES } from '../../../utils/restApiUrls';
//
import { dispatch } from '../../../redux/store';
import { enqueueSnackbar } from 'notistack';


const USERS_DETAILS_FETCH_ERROR = 'organization.error.fetchUsers';
const CREATE_USER_SUCCESS = 'organization.users.createUser';
const B_FETCH_ERROR = 'venues.error.fetchBranches';


const initialState = {
  isLoading: true,
  isUserListingLoading: false,
  isLinkingLoading: false,
  isOpenModal: false,
  error: null,
  venues:[],
  users: [],
  organisationsList: [],
  Orginization: null,
  alertError: null,
  alertVenuesListingError: null,
  alertSuccess: null,
}

const slice = createSlice({
  name: 'orginization',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startUserListingLoading(state) {
      state.isUserListingLoading = true;
    },
    startLinkingLoading(state) {
      state.isLinkingLoading = true;
    },
    stopLinkingLoading(state) {
      state.isLinkingLoading = false;
    },
    setModalOpen(state) {
      state.isOpenModal = true;
    },
    setModalClose(state) {
      state.isOpenModal = false;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    stopUserListingLoading(state) {
      state.isUserListingLoading = false;
    },
    // START LOADING
    usersFetched(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // START LOADING
    organisationsListSuccess(state, action) {
      state.isLoading = false;
      state.organisationsList = action.payload;
    },
    // START LOADING
    OrginisationFetched(state, action) {
      state.isLoading = false;
      state.Orginization = action.payload;
    },

    // GET VENUES FOR LINKING
    getVenuesSuccess(state, action) {
      state.venues = action.payload;
    },

    getVenuesError(state, action) {
      state.venues = [];
      state.alertVenuesListingError = action.payload;
    },


    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Display Alert Success
    setAlertSuccess(state, action) {
      const message = action.payload;
      state.alertSuccess = message;
    },

    // Display Alert Error
    setAlertError(state, action) {
      const message = action.payload;
      state.alertError = message;
    },

    // Reset Alert Success
    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    // Reset Alert Error
    resetAlertError(state) {
      state.alertError = null;
    },

  },
});

export default slice.reducer;

// Actions
export const { resetAlertSuccess, resetAlertError,setModalClose,setModalOpen } = slice.actions;

// ----------------------------------------------------------------------

export function getUsers(org_id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_ORGANIZATION.list.replace(":org_id", org_id));
      const { status, success, data } = response.data;
      if (success) {
        // eslint-disable-next-line camelcase
        dispatch(slice.actions.usersFetched(data))
        dispatch(slice.actions.stopUserListingLoading());
        // window.localStorage.setItem('lwt_ut', user_type);
      }
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
  }
}



// ----------------------------------------------------------------------

export function getOrgVenues(org_id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_PLACES.venues.replace(":org_id", org_id) );
      if (response.data.status) {
        dispatch(slice.actions.getVenuesSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(slice.actions.getVenuesError(error?.response?.data?.message || 'Something went wrong. Please contact to Administrator'));
    }
  };
}

// ------------------------------------------------------------------------

export function getOrganisationsList(setSelectedOrg) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_ORGANIZATION.organisationsList);
      const { status, success, data } = response.data;
      if (success) {
        // eslint-disable-next-line camelcase
        dispatch(slice.actions.organisationsListSuccess(data))
        if(data?.length){
          setSelectedOrg(data[0].id)
        }

        // window.localStorage.setItem('lwt_ut', user_type);
      }
    } catch (error) {
      // dispatch(slice.actions.stopLoading());
    }
  }
}


// ----------------------------------------------------------------------

export function getOrgDetail(org_id) {
  console.log('getting branch details');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_ORGANIZATION.profile.replace(":org_id", org_id));
      const { status, success, data } = response.data;
      console.log(response.data, "response.data");
      if (success) {
        // eslint-disable-next-line camelcase
        dispatch(slice.actions.OrginisationFetched(data))
        // window.localStorage.setItem('lwt_ut', user_type);
      }
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
  }
}



// ----------------------------------------------------------------------

export function setOrgDetail(payload, org_id) {
  console.log('getting branch details');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(URL_ORGANIZATION.profile.replace(":org_id", org_id),payload);
      const { status, success, data,message } = response.data;
      console.log(response.data, "response.data");
      if (success) {
        // eslint-disable-next-line camelcase
        console.log(message,"343");
        dispatch(slice.actions.setAlertSuccess(message));
        dispatch(slice.actions.OrginisationFetched(data));
      } else {
        dispatch(
          slice.actions.setAlertError(data.message)
        );
      }
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setAlertError(error?.response?.data?.message));
    }
  }
}


// ----------------------------------------------------------------------

export function linkUsers(payload, userId,organizationId,cancel,setStartLinkingLoading) {
  return async () => {
    try {
      const response = await axios.post(URL_ORGANIZATION.link.replace(":user_id", userId).replace(":org_id", organizationId), payload);
      const { status, success, data } = response.data;
      if (success) {
        cancel()
        setStartLinkingLoading(false)
        enqueueSnackbar('Venues linked successfully!', { variant: 'success' });
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.stopLinkingLoading());
    }
  }
}


// ----------------------------------------------------------------------

export function deleteUsers(user_id,org_id,setloading,handleClose) {
  return async () => {
    try {
      const response = await axios.delete(URL_ORGANIZATION.delete.replace(":user_id", user_id).replace(":org_id",org_id));
      const { status, success, data } = response.data;
      if (success) {
        // eslint-disable-next-line camelcase
        setloading(false)
        handleClose()
        dispatch(getUsers(org_id))
        // window.localStorage.setItem('lwt_ut', user_type);
      }
    } catch (error) {
      setloading(false)
      handleClose()
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setAlertError(error?.response?.data?.message || 'Something went wrong. Please contact to Administrator'));
    }
  }
}