import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN, ROLE_BASED_DISPLAY } from '../config';
// hooks
import useLocales from '../hooks/useLocales';
// components
import LoadingScreen from '../components/LoadingScreen';
import OnboardingLayout from '../layouts/onboarding';
import VenueList from '../pages/dashboard/venues/list/VenueList';
import PendingVenueList from '../pages/dashboard/venues/list/PendingVenuesList';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { currentLang } = useLocales();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component key={currentLang.value} {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'login', element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: 'signup', element: (
        <GuestGuard>
          <SignUp />
        </GuestGuard>
      )
    },
    {
      path: 'welcome', element: (
        <AuthGuard>
          <Welcome />
        </AuthGuard>
      )
    },
    // Onboarding Routes
    {
      path: 'onboarding',
      element: (
        <AuthGuard>
          <OnboardingLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={"/onboarding/new"} replace />, index: true },
        // { path: 'new', element: <Onboarding /> },
        { path: 'organization/:org_id/venues/new', element: <Onboarding /> },
      ],
    },

    // Onboarding Routes TEMP
    {
      path: '*',
      element: <DashboardLayout />,
      children: [
        {
          path: 'venues',
          children: [
            { element: <Navigate to="/dashboard/venues/list" replace />, index: true },
            { path: 'list', element: <VenueList version="new" /> },

          ],
        },
        {
          path: 'pending',
          children: [ { element: <Navigate to="/dashboard/pending/venues/list" replace />, index: true },
            { path: 'venues/list/', element: <PendingVenueList version="new" /> } ]
        }
      ],
    },


    // Letsmeet Routes
    {
      path: '*', // 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'overview', element: <GeneralOverview /> },

        {
          path: 'organization',
          children: [
            { element: <Navigate to="/dashboard/organization/list" replace />, index: true },
            { path: 'list', element: <Orginization /> },
            { path: ':org_id/users/new', element: <AddOrginizationUser /> },
            { path: 'users/new', element: <AddOrginizationUser /> },
            { path: ':org_id/venues/list', element: <VenueList version="new" /> },
            { path: ':org_id/venues/:venue_id/edit', element: <OnboardEdit /> },
            { path: ':org_id/venues/:venue_id/onboard', element: <MyPlaceSpaceOnboardEdit /> },
            { path: ':org_id/venues/:venue_id/spaces/:spaceid/edit', element: <MyPlaceSpaceOnboardEdit /> },
          ],
        },

        { path: 'calendar', element: <Calendar /> },

        {
          path: 'customers',
          children: [
            { element: <Navigate to="/customers/bookings" replace />, index: true },
            { path: 'bookings', element: <RoleBasedGuard accessibleRoles={ROLE_BASED_DISPLAY.BOOK}><GeneralCustomersBookings /></RoleBasedGuard> },
            { path: 'coworking', element: <RoleBasedGuard accessibleRoles={ROLE_BASED_DISPLAY.WORK}><GeneralCustomersCoworking /></RoleBasedGuard> },
            { path: 'coworking/:id', element: <RoleBasedGuard accessibleRoles={ROLE_BASED_DISPLAY.WORK}><GeneralCustomersCoworking /></RoleBasedGuard> },
          ],
        },

      ],
    },
    // {
    //   path: '*', 
    //   element: <DashboardLayout />,
    //   children: [
    //     {
    //       path: 'organisation',
    //       children: [
    //         { path: 'new', element: <Onboarding /> },
    //         { path: ':org_id/venues/:venue_id/edit', element: <OnboardEdit /> },
    //         { path: ':branchname/:venue_id/onboard', element: <MyPlaceSpaceOnboardEdit /> },
    //         { path: ':branchname/:venue_id/spaces/:spaceid/edit', element: <MyPlaceSpaceOnboardEdit /> },
    //       ],
    //     },
    //   ],
    // },

    {
      path: '*',
      element: <DashboardLayout />,
      children: [
        {
          path: 'settings',
          children: [
            { path: '*', element: <AccountSettings /> },
          ],
        },
        {
          path: 'profile',
          children: [
            { path: '*', element: <ProfileSettings /> },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    // default
    { path: '/', element: <Navigate to="/overview" replace /> },

    // Not found
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
// const Home = Loadable(lazy(() => import('../pages/auth/Home')));
const Welcome = Loadable(lazy(() => import('../pages/Welcome')));
const SignUp = Loadable(lazy(() => import('../pages/auth/SignUp')));
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const ChangePassword = Loadable(lazy(() => import('../pages/auth/ChangePassword')));
// Letsmeet Dashboard
const GeneralOverview = Loadable(lazy(() => import('../pages/dashboard/overview/GeneralOverview')));

const Orginization = Loadable(lazy(() => import('../pages/dashboard/organisation/OrganisationList')));
const AddOrginizationUser = Loadable(lazy(() => import('../pages/dashboard/organisation/AddOrginisationUser')));


const Onboarding = Loadable(lazy(() => import('../pages/dashboard/venues/VenueOnboard')));

const OnboardEdit = Loadable(lazy(() => import('../pages/dashboard/venues/VenueEdit')));
const MyPlaceSpaceOnboardEdit = Loadable(lazy(() => import('../pages/dashboard/spaces/SpaceOnboardEdit')));

//Calender
const Calendar = Loadable(lazy(() => import('../pages/dashboard/calendar/Calendar')));
//Customers
const GeneralCustomersBookings = Loadable(lazy(() => import('../pages/dashboard/customers/GeneralCustomersBookings')));
const GeneralCustomersCoworking = Loadable(lazy(() => import('../pages/dashboard/customers/GeneralCustomersCoworking')));

//Settings

const AccountSettings = Loadable(lazy(() => import('../pages/settings/OrganisationSettings')));
const ProfileSettings = Loadable(lazy(() => import('../pages/profile/ProfileSettings')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
