import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, Typography, Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
// hooks
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

Breadcrumbs.propTypes = {
  activeLast: PropTypes.bool,
  links: PropTypes.array.isRequired,
};

export default function Breadcrumbs({ links, activeLast = false, ...other }) {
  const { translate: t } = useLocales();

  const currentLink = links[links.length - 1].name;

    const listDefault = links.map((link,index) => <LinkItem key={index} linkKey={index} link={link} />);

    const listActiveLast = links.map((link,index) => (
        <div key={link.name}>
            {link.name !== currentLink ? (
                <LinkItem key={index} linkKey={index} link={link} />
            ) : (
                <Typography
                    key={link.name}
                    variant="body2"
                    sx={{
                        maxWidth: 260,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        color: 'text.disabled',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {currentLink === 'My Venues' ? t('rooms.myVenues') : currentLink}
                </Typography>
            )}
        </div>
    ));

  return (
    <MUIBreadcrumbs
      separator={<Box component="span" sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }} />}
      {...other}
    >
      {activeLast ? listDefault : listActiveLast}
    </MUIBreadcrumbs>
  );
}

// ----------------------------------------------------------------------

LinkItem.propTypes = {
  link: PropTypes.shape({
    href: PropTypes.string,
      linkKey: PropTypes.string,
    icon: PropTypes.any,
    name: PropTypes.string,
  }),
};

function LinkItem({ link ,linkKey }) {
  const { translate: t } = useLocales();
  const { href, name, icon } = link;
  return (
    <Link
        key={linkKey}
      variant="body2"
      component={RouterLink}
      to={href || '#'}
      sx={{
        lineHeight: 2,
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
        '& > div': { display: 'inherit' },
      }}
    >
      {icon && <Box sx={{ mr: 1, '& svg': { width: 20, height: 20 } }}>{icon}</Box>}
      {name === 'My Venues' ? t('rooms.myVenues') : name}
    </Link>
  );
}
