import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
// config
import { HOST_API } from '../utils/config';
// locale
import i18n from '../locales/i18n';
import useAuth from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "../redux/store";
import {PATH_AUTH} from "../routes/paths";
import {logoutResetReduxStates} from "../pages/settings/settingsSlice";

// ----------------------------------------------------------------------


export const axiosService = axios;

export const api = axios.create({
    timeout: 60 * 1000,
});

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // const org_id = window.localStorage.getItem('org_id');
    const params = { ...(config.params && config.params || {}) };
    params.lang = i18n.language;

    const newConfig = { ...config, params };
    if (accessToken) {
      newConfig.headers.Authorization = `Bearer ${accessToken}`
    }
    // Replace :org_id in the URL with the user's organization ID
    // if (org_id && newConfig.url.includes(":org_id")) {
    //   console.log(newConfig.url, newConfig.url.replace(':org_id', org_id));
    //   newConfig.url = newConfig.url.replace(':org_id', org_id);
    // }
    return newConfig;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  });

axios.interceptors.response.use(
    (response) => {
        // console.log(response,'in interceptors')
        // Check status code and implement actions
        switch (response.status) {
            case 200:
                // console.log('Request successful:', response);
                break;
            case 201:
                // console.log('Resource created:', response);
                break;
            // Add more status codes as needed
            default:
                console.log('Unhandled status code:', response.status);
        }
        return response;
    },
    async (error) => {
        if (error.response) {
            // Handle different status codes for errors
            switch (error.response.status) {
                case 401:
                    console.log('Unauthorized - Redirecting to login...');
                    window.localStorage.removeItem('accessToken');
                    window.localStorage.setItem('lwt_ut', 0);
                    delete axios.defaults.headers.Authorization;
                    window.location.href =PATH_AUTH.login
                    break;
                case 403:
                    console.log('Forbidden - Access denied.');
                    // Implement redirect or user notification
                    break;
                case 404:
                    console.log('Resource not found.');
                    // Handle 404 specific logic
                    break;
                case 500:
                    console.log('Server error - Try again later.');
                    // Handle server errors
                    break;
                default:
                    console.log(`Unhandled error status code: ${error.response.status}`);
            }
        } else {
            console.log('Error without response:', error.message);
        }
        return Promise.reject(error);
    }
);

export default axios;
